import React, { createContext, useContext, useState } from "react";

const LeagueContext = createContext();

export const LeagueProvider = ({ children }) => {
  const [selectedLeague, setSelectedLeague] = useState(() => {
    return localStorage.getItem('selectedLeague') || 'NHL';
  });

  const updateLeague = (league) => {
    if (league !== selectedLeague) {
      setSelectedLeague(league);
      localStorage.setItem('selectedLeague', league);
    }
  };

  return (
    <LeagueContext.Provider value={{ selectedLeague, updateLeague }}>
      {children}
    </LeagueContext.Provider>
  );
};

export const useLeagueContext = () => {
  const context = useContext(LeagueContext);
  if (!context) {
    throw new Error('useLeagueContext must be used within a LeagueProvider');
  }
  return context;
};
