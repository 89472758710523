import { State, Country } from "country-state-city";

// Common country name mappings following ISO 3166-1 alpha-2 codes
const COUNTRY_MAPPINGS = {
  "united states": "US",
  "usa": "US",
  "united states of america": "US",
  "canada": "CA",
  "mexico": "MX",
  "united kingdom": "GB",
  "uk": "GB",
  "australia": "AU",
  "germany": "DE",
  "france": "FR",
  "italy": "IT",
  "spain": "ES",
  "japan": "JP",
  "china": "CN",
  "india": "IN",
  "brazil": "BR"
};

// US state abbreviations following USPS standards
const US_STATE_MAPPINGS = {
  "alabama": "AL",
  "alaska": "AK",
  "arizona": "AZ",
  "arkansas": "AR",
  "california": "CA",
  "colorado": "CO",
  "connecticut": "CT",
  "delaware": "DE",
  "district of columbia": "DC",
  "florida": "FL",
  "georgia": "GA",
  "hawaii": "HI",
  "idaho": "ID",
  "illinois": "IL",
  "indiana": "IN",
  "iowa": "IA",
  "kansas": "KS",
  "kentucky": "KY",
  "louisiana": "LA",
  "maine": "ME",
  "maryland": "MD",
  "massachusetts": "MA",
  "michigan": "MI",
  "minnesota": "MN",
  "mississippi": "MS",
  "missouri": "MO",
  "montana": "MT",
  "nebraska": "NE",
  "nevada": "NV",
  "new hampshire": "NH",
  "new jersey": "NJ",
  "new mexico": "NM",
  "new york": "NY",
  "north carolina": "NC",
  "north dakota": "ND",
  "ohio": "OH",
  "oklahoma": "OK",
  "oregon": "OR",
  "pennsylvania": "PA",
  "rhode island": "RI",
  "south carolina": "SC",
  "south dakota": "SD",
  "tennessee": "TN",
  "texas": "TX",
  "utah": "UT",
  "vermont": "VT",
  "virginia": "VA",
  "washington": "WA",
  "west virginia": "WV",
  "wisconsin": "WI",
  "wyoming": "WY"
};

// Canadian province abbreviations following Canada Post standards
const CA_PROVINCE_MAPPINGS = {
  "alberta": "AB",
  "british columbia": "BC",
  "manitoba": "MB",
  "new brunswick": "NB",
  "newfoundland and labrador": "NL",
  "nova scotia": "NS",
  "northwest territories": "NT",
  "nunavut": "NU",
  "ontario": "ON",
  "prince edward island": "PE",
  "quebec": "QC",
  "saskatchewan": "SK",
  "yukon": "YT"
};

// Common city abbreviations following postal service standards
const COMMON_CITY_PREFIXES = {
  "north": "N",
  "south": "S",
  "east": "E",
  "west": "W",
  "new": "N",
  "saint": "ST",
  "fort": "FT",
  "mount": "MT",
  "port": "PT",
  "lake": "LK"
};

export function getStateAbbreviation(stateName, countryName = "") {
  if (!stateName || stateName === "-") return "-";

  // Convert to lowercase for comparison
  const stateNameLower = stateName.toLowerCase().trim();
  const countryNameLower = countryName.toLowerCase().trim();

  // Check if it's already an abbreviation (2-3 characters)
  if (stateName.length <= 3) return stateName.toUpperCase();

  // Get country code
  const countryCode = getCountryCode(countryName);

  // Check US states
  if (countryCode === "US" || !countryCode) {
    if (US_STATE_MAPPINGS[stateNameLower]) {
      return US_STATE_MAPPINGS[stateNameLower];
    }
  }

  // Check Canadian provinces
  if (countryCode === "CA") {
    if (CA_PROVINCE_MAPPINGS[stateNameLower]) {
      return CA_PROVINCE_MAPPINGS[stateNameLower];
    }
  }

  // Try to find state in country-state-city database
  const states = State.getStatesOfCountry(countryCode);
  const state = states.find(s => s.name.toLowerCase() === stateNameLower);
  if (state?.isoCode) {
    const parts = state.isoCode.split("-");
    return parts[parts.length - 1];
  }

  // If no match found, create standardized abbreviation
  return createStandardAbbreviation(stateName);
}

function createStandardAbbreviation(name) {
  if (!name || name === "-") return "-";
  
  // Remove special characters and extra spaces
  name = name.replace(/[^\w\s]/g, '').trim();
  
  // Split the name into words
  const words = name.split(/\s+/);

  if (words.length === 1) {
    // For single word, return first 3 letters
    return name.substring(0, 3).toUpperCase();
  } else {
    // Check for common prefixes first
    const firstWordLower = words[0].toLowerCase();
    if (COMMON_CITY_PREFIXES[firstWordLower]) {
      // If first word is a common prefix, use its abbreviation
      return words.map((word, index) => {
        if (index === 0) {
          return COMMON_CITY_PREFIXES[firstWordLower];
        }
        return word[0];
      }).join('').toUpperCase();
    }
    
    // For multiple words, use first letter of each significant word
    return words
      .filter(word => word.length > 1) // Filter out single-letter words
      .map(word => word[0])
      .join('')
      .toUpperCase();
  }
}

export function getCountryCode(countryName) {
  if (!countryName || countryName === "-") return "";

  const countryLower = countryName.toLowerCase().trim();

  // Check common mappings first (ISO 3166-1 alpha-2)
  if (COUNTRY_MAPPINGS[countryLower]) {
    return COUNTRY_MAPPINGS[countryLower];
  }

  // Try to find in country-state-city database
  const country = Country.getAllCountries().find(
    c => c.name.toLowerCase() === countryLower
  );

  return country ? country.isoCode : countryLower.toUpperCase();
}

// Function to get standardized city abbreviation
export function getCityAbbreviation(city) {
  if (!city || city === "-") return "-";
  
  // Remove special characters and extra spaces
  city = city.replace(/[^\w\s]/g, '').trim();
  
  // Split into words
  const words = city.toLowerCase().split(/\s+/);
  
  if (words.length === 1) {
    // Single word city - use first 3 letters
    return city.substring(0, 3).toUpperCase();
  }
  
  // Check if first word is a common prefix
  if (COMMON_CITY_PREFIXES[words[0]]) {
    const prefix = COMMON_CITY_PREFIXES[words[0]];
    const remaining = words.slice(1).map(word => word[0]).join('');
    return (prefix + remaining).toUpperCase();
  }
  
  // For other multi-word cities, use first letter of each significant word
  return words
    .filter(word => word.length > 1) // Filter out single-letter words
    .map(word => word[0])
    .join('')
    .toUpperCase();
}
