import React, { useEffect, useState, forwardRef } from "react";
import "./tableComponent.css";
import { useLeagueContext } from "../LeagueContext";
import TimeFormat from "../../services/TimeFormat";
import { getUserById } from "../../Apis/auth";
import { getGamePlayedByUserId } from "../../Apis/predictions";
import DatePicker from "react-datepicker";
// import CloseIcon from "@mui/icons-material/CancelOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { headerOptions } from "./data"; // Import headerOptions
import { TextField, InputAdornment, IconButton } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CancelOutlined from "@mui/icons-material/CancelOutlined";
import ArrowForward from "@mui/icons-material/ArrowForward";
import Loader from "../Loader/Loader"; // Import the custom Loader component
import CalendarTodayIcon from "@mui/icons-material/CalendarToday"; // Import MUI Calendar Icon
import ArrowBack from "@mui/icons-material/ArrowBack";
import UBRbg from "../../assets/UBR-bg.png";
import {
  getStateAbbreviation,
  getCountryCode,
} from "../../utils/stateAbbreviations";
import ReactCountryFlag from "react-country-flag";

// League specific scoring rules
const LEAGUE_POINTS = {
  NHL: {
    REG: 1,
    OT: 1,
    SO: 2
  },
  NBA: {
    REG: 1,
    OT: 2
  },
  NFL: {
    REG: 1,
    OT: 5
  },
  MLB: {
    REG: 1,
    EI: 2  // Extra Innings for MLB
  }
};

// Modified calculation functions for all leagues
const calculateReg = (row, league) => {
  const regValue = row.result?.endingsPoints?.pickRegulation || 0;
  const otValue = row.result?.endingsPoints?.pickOverTime || 0;
  const soValue = row.result?.endingsPoints?.pickShootout || 0;
  const eiValue = row.result?.endingsPoints?.pickExtraInnings || 0;

  switch(league) {
    case "NHL":
      // If any NHL ending was picked correctly
      if (regValue > 0 || otValue > 0 || soValue > 0) {
        return LEAGUE_POINTS[league]?.REG || 0;
      }
      break;
    
    case "NBA":
    case "NFL":
      // If either REG or OT was picked correctly
      if (regValue > 0 || otValue > 0) {
        return LEAGUE_POINTS[league]?.REG || 0;
      }
      break;
    
    case "MLB":
      // If either REG or EI was picked correctly
      if (regValue > 0 || eiValue > 0) {
        return LEAGUE_POINTS[league]?.REG || 0;
      }
      break;
  }
  return 0;
};

const calculateOT = (row, league) => {
  const regValue = row.result?.endingsPoints?.pickRegulation || 0;
  const otValue = row.result?.endingsPoints?.pickOverTime || 0;
  const soValue = row.result?.endingsPoints?.pickShootout || 0;

  // Show OT points if either OT or SO was picked correctly
  if (otValue > 0 || soValue > 0) {
    return LEAGUE_POINTS[league]?.OT || 0;
  }
  if (regValue > 0) {
    return null;
  }
  return 0;
};

const calculateSO = (row, league) => {
  const regValue = row.result?.endingsPoints?.pickRegulation || 0;
  const otValue = row.result?.endingsPoints?.pickOverTime || 0;
  const soValue = row.result?.endingsPoints?.pickShootout || 0;

  // If SO was picked correctly
  if (soValue > 0) {
    return LEAGUE_POINTS[league]?.SO || 0; // Return 2
  }
  // If REG or OT was picked correctly, show blank
  if (regValue > 0 || otValue > 0) {
    return null;
  }
  return 0; // Show ❌
};

const calculateEI = (row, league) => {
  const regValue = row.result?.endingsPoints?.pickRegulation || 0;
  const eiValue = row.result?.endingsPoints?.pickExtraInnings || 0;

  // If EI was picked correctly
  if (eiValue > 0) {
    return LEAGUE_POINTS[league]?.EI || 0; // Return 2
  }
  // If REG was picked correctly, show blank
  if (regValue > 0) {
    return null;
  }
  return 0; // Show ❌
};

const calculateMetrics = (row, league) => {
  const oneS = parseFloat((row.result?.accuracyPoints?.home?.p1s || 0) + (row.result?.accuracyPoints?.vistor?.p1s || 0)).toFixed(1);
  const oneSO = parseFloat((row.result?.accuracyPoints?.home?.p1s0 || 0) + (row.result?.accuracyPoints?.vistor?.p1s0 || 0)).toFixed(1);
  const twoSO = parseFloat((row.result?.accuracyPoints?.home?.p2s0 || 0) + (row.result?.accuracyPoints?.vistor?.p2s0 || 0)).toFixed(1);
  const oneSW3 = parseFloat((row.result?.accuracyPoints?.home?.p1s3p || 0) + (row.result?.accuracyPoints?.vistor?.p1s3p || 0)).toFixed(1);
  const twoSW3 = parseFloat((row.result?.accuracyPoints?.home?.p2s3p || 0) + (row.result?.accuracyPoints?.vistor?.p2s3p || 0)).toFixed(1);
  const oneSW7 = parseFloat((row.result?.accuracyPoints?.home?.p1s7p || 0) + (row.result?.accuracyPoints?.vistor?.p1s7p || 0)).toFixed(1);
  const twoSW7 = parseFloat((row.result?.accuracyPoints?.home?.p2s7p || 0) + (row.result?.accuracyPoints?.vistor?.p2s7p || 0)).toFixed(1);
  const oneSW2 = parseFloat((row.result?.accuracyPoints?.home?.p1s2p || 0) + (row.result?.accuracyPoints?.vistor?.p1s2p || 0)).toFixed(1);
  const twoSW2 = parseFloat((row.result?.accuracyPoints?.home?.p2s2p || 0) + (row.result?.accuracyPoints?.vistor?.p2s2p || 0)).toFixed(1);

  // Log raw values for debugging
  console.log('Raw 2S0 values:', {
    home: row.result?.accuracyPoints?.home?.p2s0,
    visitor: row.result?.accuracyPoints?.vistor?.p2s0,
    calculated: twoSO
  });

  return {
    oneS,
    oneSO,
    twoSO,
    oneSW3,
    twoSW3,
    oneSW7,
    twoSW7,
    oneSW2,
    twoSW2
  };
};

const TableComponent = ({ initialDate }) => {
  const { selectedLeague } = useLeagueContext();
  const [filteredHeaderOptions, setFilteredHeaderOptions] = useState([]);
  const [gamesPlayed, setGamesPlayed] = useState([]);
  const [gameDataMap, setGameDataMap] = useState({});
  const id = localStorage.getItem("_id");
  const [selectedDate, setSelectedDate] = useState(initialDate || (() => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    yesterday.setHours(0, 0, 0, 0);
    return yesterday;
  }));
  const [isLoading, setIsLoading] = useState(false);

  const getUser = () => {
    return getUserById(id).then((res) => res.data);
  };
  // Function to move the date backward by one day
  const handlePreviousDay = () => {
    setSelectedDate(prevDate => {
      const newDate = new Date(prevDate);
      newDate.setDate(newDate.getDate() - 1);
      newDate.setHours(0, 0, 0, 0);
      return newDate;
    });
    setIsLoading(true);
  };

  // Function to move the date forward by one day
  const handleNextDay = () => {
    setSelectedDate(prevDate => {
      const newDate = new Date(prevDate);
      newDate.setDate(newDate.getDate() + 1);
      newDate.setHours(0, 0, 0, 0);
      return newDate;
    });
    setIsLoading(true);
  };

  // Function to check if a date is in the future
  const isFutureDate = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    const compareDate = new Date(date);
    compareDate.setHours(0, 0, 0, 0);
    
    return compareDate > today;
  };

  // Function to format date for display
  const formatDate = (date) => {
    if (!date) return '';
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    return `${month}/${day}/${year}`;
  };

  const getResult = (userData) => {
    setIsLoading(true);
    getGamePlayedByUserId(id)
      .then((res) => {
        if (res.data && res.data.data) {
          const gamesPlayed = Array.isArray(res.data.data.gamesPlayed)
            ? res.data.data.gamesPlayed
            : [];
          const gameDataArray = Array.isArray(res.data.data.gameData)
            ? res.data.data.gameData
            : [];

          const gameDataLookup = {};
          gameDataArray.forEach((game) => {
            if (game && game._id) {
              gameDataLookup[game._id] = game;
            }
          });

          setGameDataMap(gameDataLookup);

          const filteredGamesPlayed = gamesPlayed.filter((game) => {
            if (!game || !game.gameStartTime) {
              return false;
            }

            const gameDate = new Date(game.gameStartTime);
            gameDate.setHours(0, 0, 0, 0);

            const selected = new Date(selectedDate);
            selected.setHours(0, 0, 0, 0);


            return (
              game.league === selectedLeague &&
              gameDate.getTime() === selected.getTime()
            );
          });

          const enhancedData = filteredGamesPlayed.map((playedGame) => {
            const correspondingGame = gameDataLookup[playedGame._id] || {};
            return {
              ...playedGame,
              ...correspondingGame,
              co: userData.country || "-",
              state: userData.state || "-",
              city: userData.city || "-",
              player: userData.leagues[0]?.username || "-",
              BR: playedGame?.result?.perfectScore != null
                ? parseFloat(playedGame?.result?.perfectScore).toFixed(2)
                : "-",
              vegasOdds: playedGame?.result?.vegasOdds || {},
            };
          });

          setGamesPlayed(enhancedData);
        } else {
          setGamesPlayed([]);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching game data:", error);
        setIsLoading(false);
        setGamesPlayed([]);
      });
  };

  useEffect(() => {
    if (selectedLeague && headerOptions[selectedLeague]) {
      setFilteredHeaderOptions(headerOptions[selectedLeague]);
    } else {
      setFilteredHeaderOptions([]);
    }

    getUser().then((userData) => {
      getResult(userData);
    });
  }, [selectedLeague, selectedDate]);

  // Function to calculate TP (Total Points)
  const calculateTP = (games) => {
    return games.map((row) => {
      let tp;
      
      switch(selectedLeague) {
        case "NHL":
          const ubr = parseFloat(row.BR || 0);
          const ml = parseFloat(row.result?.vegasOdds?.pickingFavorite || row.result?.vegasOdds?.pickingUnderdog || 0);
          const spread = parseFloat(row.result?.vegasOdds?.pickingSpread?.vSpreadPoints || row.result?.vegasOdds?.pickingSpread?.hSpreadPoints || 0);
          const ou = parseFloat(row.result?.vegasOdds?.pickingOver || row.result?.vegasOdds?.pickingUnder || 0);
          const oneS = parseFloat((row.result?.accuracyPoints?.home?.p1s || 0) + (row.result?.accuracyPoints?.vistor?.p1s || 0));
          const oneSO = parseFloat((row.result?.accuracyPoints?.home?.p1s0 || 0) + (row.result?.accuracyPoints?.vistor?.p1s0 || 0));
          const reg = parseFloat(calculateReg(row, "NHL") || 0);
          const ot = parseFloat(calculateOT(row, "NHL") || 0);
          const so = parseFloat(calculateSO(row, "NHL") || 0);

          // Sum all values and format to one decimal
          tp = (ubr + ml + spread + ou + oneS + oneSO + reg + ot + so).toFixed(1);
          break;

        case "NBA":
          const nbaUbr = parseFloat(row.BR || 0);
          const nbaMl = parseFloat(row.result?.vegasOdds?.pickingFavorite || row.result?.vegasOdds?.pickingUnderdog || 0);
          const nbaSpread = parseFloat(row.result?.vegasOdds?.pickingSpread?.vSpreadPoints || row.result?.vegasOdds?.pickingSpread?.hSpreadPoints || 0);
          const nbaOu = parseFloat(row.result?.vegasOdds?.pickingOver || row.result?.vegasOdds?.pickingUnder || 0);
          const nbaOneS = parseFloat((row.result?.accuracyPoints?.home?.p1s || 0) + (row.result?.accuracyPoints?.vistor?.p1s || 0));
          const nbaOneSO = parseFloat((row.result?.accuracyPoints?.home?.p1s0 || 0) + (row.result?.accuracyPoints?.vistor?.p1s0 || 0));
          const nbaOneSW3 = parseFloat((row.result?.accuracyPoints?.home?.p1s3p || 0) + (row.result?.accuracyPoints?.vistor?.p1s3p || 0));
          const nbaTwoSW3 = parseFloat((row.result?.accuracyPoints?.home?.p2s3p || 0) + (row.result?.accuracyPoints?.vistor?.p2s3p || 0));
          const nbaOneSW7 = parseFloat((row.result?.accuracyPoints?.home?.p1s7p || 0) + (row.result?.accuracyPoints?.vistor?.p1s7p || 0));
          const nbaTwoSW7 = parseFloat((row.result?.accuracyPoints?.home?.p2s7p || 0) + (row.result?.accuracyPoints?.vistor?.p2s7p || 0));
          const nbaReg = parseFloat(calculateReg(row, "NBA") || 0);
          const nbaOt = parseFloat(calculateOT(row, "NBA") || 0);

          // Sum all values and format to one decimal
          tp = (
            nbaUbr + 
            nbaMl + 
            nbaSpread + 
            nbaOu + 
            nbaOneS + 
            nbaOneSO + 
            nbaOneSW3 + 
            nbaTwoSW3 + 
            nbaOneSW7 + 
            nbaTwoSW7 + 
            nbaReg + 
            nbaOt
          ).toFixed(1);

          // Log values for debugging
          console.log('NBA TP Calculation:', {
            nbaUbr,
            nbaMl,
            nbaSpread,
            nbaOu,
            nbaOneS,
            nbaOneSO,
            nbaOneSW3,
            nbaTwoSW3,
            nbaOneSW7,
            nbaTwoSW7,
            nbaReg,
            nbaOt,
            total: tp
          });
          break;

        case "MLB":
          tp = parseFloat(
            // UBR
            (row.BR || 0) +
            // ML
            (row.result?.vegasOdds?.pickingFavorite || row.result?.vegasOdds?.pickingUnderdog || 0) +
            // Spread
            (row.result?.vegasOdds?.pickingSpread?.vSpreadPoints || row.result?.vegasOdds?.pickingSpread?.hSpreadPoints || 0) +
            // O/U
            (row.result?.vegasOdds?.pickingOver || row.result?.vegasOdds?.pickingUnder || 0) +
            // 1S
            ((row.result?.accuracyPoints?.home?.p1s || 0) + (row.result?.accuracyPoints?.vistor?.p1s || 0)) +
            // 1S0
            ((row.result?.accuracyPoints?.home?.p1s0 || 0) + (row.result?.accuracyPoints?.vistor?.p1s0 || 0)) +
            // 1SW2
            ((row.result?.accuracyPoints?.home?.p1s2p || 0) + (row.result?.accuracyPoints?.vistor?.p1s2p || 0)) +
            // 2SW2
            ((row.result?.accuracyPoints?.home?.p2s2p || 0) + (row.result?.accuracyPoints?.vistor?.p2s2p || 0)) +
            // Reg
            (calculateReg(row, "MLB") || 0) +
            // EI
            (calculateEI(row, "MLB") || 0)
          ).toFixed(1);
          break;

        case "NFL":
          const metrics = calculateMetrics(row, "NFL");
          const nflUbr = parseFloat(row.BR || 0);
          const nflMl = parseFloat(row.result?.vegasOdds?.pickingFavorite || row.result?.vegasOdds?.pickingUnderdog || 0);
          const nflSpread = parseFloat(row.result?.vegasOdds?.pickingSpread?.vSpreadPoints || row.result?.vegasOdds?.pickingSpread?.hSpreadPoints || 0);
          const nflOu = parseFloat(row.result?.vegasOdds?.pickingOver || row.result?.vegasOdds?.pickingUnder || 0);
          const nflOneS = parseFloat(metrics.oneS);
          const nflOneSO = parseFloat(metrics.oneSO);
          const nflTwoSO = parseFloat(metrics.twoSO);
          const nflOneSW3 = parseFloat(metrics.oneSW3);
          const nflTwoSW3 = parseFloat(metrics.twoSW3);
          const nflOneSW7 = parseFloat(metrics.oneSW7);
          const nflTwoSW7 = parseFloat(metrics.twoSW7);
          const nflReg = parseFloat(calculateReg(row, "NFL") || 0);
          const nflOt = parseFloat(calculateOT(row, "NFL") || 0);

          // Log raw values for debugging
          console.log('NFL Raw Values:', {
            metrics,
            nflTwoSO,
            total: tp
          });

          // Sum all values and format to one decimal
          tp = (
            nflUbr + 
            nflMl + 
            nflSpread + 
            nflOu + 
            nflOneS + 
            nflOneSO + 
            nflTwoSO + 
            nflOneSW3 + 
            nflTwoSW3 + 
            nflOneSW7 + 
            nflTwoSW7 + 
            nflReg + 
            nflOt
          ).toFixed(1);

          // Log values for debugging
          console.log('NFL TP Calculation:', {
            nflUbr,
            nflMl,
            nflSpread,
            nflOu,
            nflOneS,
            nflOneSO,
            nflTwoSO,
            nflOneSW3,
            nflTwoSW3,
            nflOneSW7,
            nflTwoSW7,
            nflReg,
            nflOt,
            total: tp
          });
          break;

        default:
          tp = "0.0";
      }
      
      return { ...row, tp };
    });
  };

  // Function to sort games by time (from the game's time value)
  const sortGamesByTime = (games) => {
    return games.sort((a, b) => {
      // Assuming `gameData?.time` is in a format that can be converted to Date
      const timeA = new Date(a?.gameData?.time);
      const timeB = new Date(b?.gameData?.time);
      return timeB - timeA; // Sort in descending order (latest time first)
    });
  };

  // Main function to calculate TP and Rank (time sorted)
  const calculateTPandRank = (games) => {
    const gamesWithTP = calculateTP(games); // Calculate TP
    const timeSortedGames = sortGamesByTime(gamesWithTP); // Sort by time

    const ranks = timeSortedGames.map((game, index) => index + 1); // Assign ranks
    return { sortedGames: timeSortedGames, ranks };
  };

  // Example usage
  const { sortedGames, ranks } = calculateTPandRank(gamesPlayed);

  const renderColumns = (row) => {
    const Reg = calculateReg(row, selectedLeague);
    const OT = calculateOT(row, selectedLeague);
    const SO = calculateSO(row, selectedLeague);
    const EI = calculateEI(row, selectedLeague);

    const renderCell = (value) => {
      if (value === null) {
        // Show blank for null (when other ending was correct)
        return "";
      } else if (parseFloat(value) === 0) {
        // Show cross for 0 (when nothing was correct)
        return <CloseIcon style={{ color: "red", fontSize: "1.3em" }} />;
      } else {
        // Show points value
        return value;
      }
    };

    switch (selectedLeague) {
      case "NHL":
        return (
          <>
            <td className="text-xs font-medium text-center"
              style={{
                border: "2px solid black",
                color: Reg === null ? "white" : (parseFloat(Reg) === 0 ? "red" : "white"),
                backgroundColor: parseFloat(Reg) > 0 ? "green" : "transparent",
                textShadow: parseFloat(Reg) > 0 ? "2px 2px 3px black" : "none",
              }}>
            {renderCell(Reg)}
          </td>
          <td className="text-xs font-medium text-center"
            style={{
              border: "2px solid black",
              color: OT === null ? "white" : (parseFloat(OT) === 0 ? "red" : "white"),
              backgroundColor: parseFloat(OT) > 0 ? "green" : "transparent",
              textShadow: parseFloat(OT) > 0 ? "2px 2px 3px black" : "none",
            }}>
            {renderCell(OT)}
          </td>
          <td className="text-xs font-medium text-center"
            style={{
              border: "2px solid black",
              color: SO === null ? "white" : (parseFloat(SO) === 0 ? "red" : "white"),
              backgroundColor: parseFloat(SO) > 0 ? "green" : "transparent",
              textShadow: parseFloat(SO) > 0 ? "2px 2px 3px black" : "none",
            }}>
            {renderCell(SO)}
          </td>
        </>
      );

      case "NBA":
      case "NFL":
        return (
          <>
            <td className="text-xs font-medium text-center"
              style={{
                border: "2px solid black",
                color: Reg === null ? "white" : (parseFloat(Reg) === 0 ? "red" : "white"),
                backgroundColor: parseFloat(Reg) > 0 ? "green" : "transparent",
                textShadow: parseFloat(Reg) > 0 ? "2px 2px 3px black" : "none",
              }}>
              {renderCell(Reg)}
            </td>
            <td className="text-xs font-medium text-center"
              style={{
                border: "2px solid black",
                color: OT === null ? "white" : (parseFloat(OT) === 0 ? "red" : "white"),
                backgroundColor: parseFloat(OT) > 0 ? "green" : "transparent",
                textShadow: parseFloat(OT) > 0 ? "2px 2px 3px black" : "none",
              }}>
              {renderCell(OT)}
            </td>
          </>
        );

      case "MLB":
        return (
          <>
            <td className="text-xs font-medium text-center"
              style={{
                border: "2px solid black",
                color: Reg === null ? "white" : (parseFloat(Reg) === 0 ? "red" : "white"),
                backgroundColor: parseFloat(Reg) > 0 ? "green" : "transparent",
                textShadow: parseFloat(Reg) > 0 ? "2px 2px 3px black" : "none",
              }}>
              {renderCell(Reg)}
            </td>
            <td className="text-xs font-medium text-center"
              style={{
                border: "2px solid black",
                color: EI === null ? "white" : (parseFloat(EI) === 0 ? "red" : "white"),
                backgroundColor: parseFloat(EI) > 0 ? "green" : "transparent",
                textShadow: parseFloat(EI) > 0 ? "2px 2px 3px black" : "none",
              }}>
              {renderCell(EI)}
            </td>
          </>
        );
    }
  };
  // Custom Input with Calendar Icon
  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <TextField
      ref={ref}
      onClick={onClick}
      value={value}
      variant="outlined"
      fullWidth
      style={{ borderRadius: "10px", height: "56px", width: "190px" }} // Styling for the input field
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={onClick}>
              <CalendarTodayIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  ));

  const renderCell = (item, column) => {
    if (column.key === "state") {
      return getStateAbbreviation(item[column.key], item.co) || "-";
    }
    if (column.key === "co") {
      return getCountryCode(item[column.key]) || item[column.key] || "-";
    }
    // ... rest of the renderCell function ...
  };

  // Add this helper function for city abbreviation
  const getCityAbbreviation = (city) => {
    if (!city || city === "-") return "-";
    const words = city.split(' ');
    if (words.length === 1) {
      return city.substring(0, 3).toUpperCase();
    }
    return words.map(word => word[0]).join('').toUpperCase();
  };

  return (
    <div className="table-container">
      <div className="date-filter-container mb-3">
        <div className="row">
          <div className="col-md-3">
            <label
              htmlFor="selectedDate"
              className="form-label"
              style={{
                color: "#dba418",
                marginLeft: "70px",
                fontWeight: "bold",
                marginBottom: "-5px",
              }}
            >
              D a t e :
            </label>
            <div className="input-group">
              <button
                className="btn btn-secondary"
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: " 10px 10px",
                }}
                onClick={handlePreviousDay}
              >
                <ArrowBack style={{ fontSize: "1.5rem", color: "#dba418" }} />{" "}
              </button>
              <div>
                <DatePicker
                  selected={selectedDate}
                  onChange={(date) => {
                    // Ensure the new date is also in EST/EDT
                    const utc = date.getTime() + (date.getTimezoneOffset() * 60000);
                    const estDate = new Date(utc + (3600000 * -4)); // EST/EDT offset
                    setSelectedDate(estDate);
                  }}
                  dateFormat="MM/dd/yyyy"
                  customInput={<CustomInput />} // Use custom input with calendar icon
                  style={{ border: "none" }}
                />
              </div>
              <button
                className="btn btn-secondary"
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "10px 10px",
                }}
                onClick={handleNextDay}
                disabled={isFutureDate(selectedDate)}
              >
                <ArrowForward
                  style={{ fontSize: "1.5rem", color: "#dba418" }}
                />{" "}
              </button>
            </div>
          </div>
        </div>
      </div>

      <table>
        <thead>
          <tr>
            {filteredHeaderOptions.map((item, ind) => (
              <th
                key={ind}
                style={{
                  color: "#dba418",
                  fontWeight: "bold",
                  fontSize: "15px",
                }}
                className="text-xs font-medium"
              >
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <div style={{ height: "15px" }}></div>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={filteredHeaderOptions.length}>
                <div
                  style={{
                    position: "relative",
                    height: "100px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ transform: "scale(0.5)" }}>
                    <Loader />
                  </div>
                </div>
              </td>
            </tr>
          ) : sortedGames.length > 0 ? (
            sortedGames.map((row, index) => {
              const gameData = gameDataMap[row.gameData] || {};
              const tp = row.tp;

              const metrics = calculateMetrics(row, selectedLeague);

              const vegasOddsValue = row.vegasOdds?.pickExtraInnings || "0";
              const ml = parseFloat(
                row.result?.vegasOdds?.pickingFavorite ||
                row.result?.vegasOdds?.pickingUnderdog ||
                0
              ).toFixed(2);
              // Check if the values match
              const isPredictionCorrect =
                (row.pick_visitor || "-") === (gameData.vFinalScore || "-") &&
                (row.pick_home || "-") === (gameData.hFinalScore || "-");

              // Set the color based on the prediction
              const predictionColortext = isPredictionCorrect
                ? "green"
                : "initial";
              const predictionColor = isPredictionCorrect ? "#dba418" : "white";

              // Check if the final score is available
              const isFinalScoreAvailable =
                gameData.vFinalScore !== undefined &&
                gameData.hFinalScore !== undefined;

              // Determine if visitor or home team won based on the final score
              const isVisitorWinnerFinal =
                isFinalScoreAvailable &&
                gameData.vFinalScore > gameData.hFinalScore;

              const isHomeWinnerFinal =
                isFinalScoreAvailable &&
                gameData.hFinalScore > gameData.vFinalScore;

              // Check if the predicted score is available
              const isPredictionAvailable =
                row.pick_visitor !== undefined && row.pick_home !== undefined;

              // Determine if visitor or home team won based on the predicted score
              const isVisitorWinnerPrediction =
                isPredictionAvailable && row.pick_visitor > row.pick_home;
              const isHomeWinnerPrediction =
                isPredictionAvailable && row.pick_home > row.pick_visitor;
              const ou = parseFloat(
                row.result?.vegasOdds?.pickingOver ||
                  row.result?.vegasOdds?.pickingUnder ||
                  0
              ).toFixed(2);

              const spread = parseFloat(
                row.result?.vegasOdds?.pickingSpread?.vSpreadPoints ||
                  row.result?.vegasOdds?.pickingSpread?.hSpreadPoints ||
                  0
              ).toFixed(2);

              return (
                <tr
                  key={index}
                  className="h-14 bg-[#181818] text-white row-slide-in"
                  style={{
                    marginTop: "-30px",
                    animationDelay: `${index * 0.05}s`,
                  }}
                >
                  <td
                    className="text-xs font-medium text-center"
                    style={{
                      backgroundColor: isVisitorWinnerFinal
                        ? "green"
                        : "initial",
                      color: isVisitorWinnerFinal ? "#dba418" : "white",
                      fontSize: isVisitorWinnerFinal ? "16px" : "",
                      textShadow: isVisitorWinnerFinal
                        ? "2px 2px 3px black"
                        : "none",

                      border: "2px solid black",
                    }}
                  >
                    {headerOptions[gameData?.visitor] || gameData?.visitor}
                  </td>
                  <td
                    className="text-xs font-medium text-center"
                    style={{
                      backgroundColor: isHomeWinnerFinal ? "green" : "initial",
                      color: isHomeWinnerFinal ? "#dba418" : "white",
                      border: "2px solid black",

                      fontSize: isHomeWinnerFinal ? "16px" : "",
                      textShadow: isHomeWinnerFinal
                        ? "2px 2px 3px black"
                        : "none",
                    }}
                  >
                    {headerOptions[gameData?.home] || gameData?.home}
                  </td>
                  <td
                    className="text-center"
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      // backgroundColor: "#078C03",
                      textShadow: "2px 2px 3px black",
                    }}
                  >
                    <span
                      style={
                        {
                          // color: isVisitorWinnerFinal ? "green" : "red",
                          // textUnderlineOffset : '2'
                        }
                      }
                    >
                      {gameData.vFinalScore || "-"}
                    </span>{" "}
                    -{" "}
                    <span
                      style={
                        {
                          // color: isHomeWinnerFinal ? "green" : "red",
                        }
                      }
                    >
                      {gameData.hFinalScore || "-"}
                    </span>
                  </td>
                  <td
                    className="text-center"
                    style={{
                      fontSize: "16px",
                      textShadow: "2px 2px 3px black",
                      fontWeight: "bold",
                      border: "2px solid black",
                      backgroundColor: (() => {
                        // Get the winner from final scores
                        const finalVisitorWon = parseInt(gameData.vFinalScore) > parseInt(gameData.hFinalScore);
                        const finalHomeWon = parseInt(gameData.hFinalScore) > parseInt(gameData.vFinalScore);
                        
                        // Get the winner from predictions
                        const predictedVisitorWon = parseInt(row.pick_visitor) > parseInt(row.pick_home);
                        const predictedHomeWon = parseInt(row.pick_home) > parseInt(row.pick_visitor);
                        
                        // Return green if prediction matches final result
                        return (finalVisitorWon && predictedVisitorWon) || (finalHomeWon && predictedHomeWon) 
                          ? "green" 
                          : "transparent";
                      })(),
                      color: (() => {
                        // Get the winner from final scores
                        const finalVisitorWon = parseInt(gameData.vFinalScore) > parseInt(gameData.hFinalScore);
                        const finalHomeWon = parseInt(gameData.hFinalScore) > parseInt(gameData.vFinalScore);
                        
                        // Get the winner from predictions
                        const predictedVisitorWon = parseInt(row.pick_visitor) > parseInt(row.pick_home);
                        const predictedHomeWon = parseInt(row.pick_home) > parseInt(row.pick_visitor);
                        
                        // Return gold if prediction matches final result
                        return (finalVisitorWon && predictedVisitorWon) || (finalHomeWon && predictedHomeWon)
                          ? "#dba418"
                          : "white";
                      })()
                    }}
                  >
                    {row.pick_visitor || "-"} - {row.pick_home || "-"}
                  </td>
                  <td
                    className="text-xs font-medium text-center"
                    style={{ color: "#fbfbd4" }}
                  >
                    {new Date(row.createdAt).toLocaleTimeString()}
                  </td>
                  <td
                    className="text-xs font-medium text-center"
                    style={{ color: "#fbfbd4" }}
                  >
                    {row.co ? (
                      <ReactCountryFlag
                        countryCode={getCountryCode(row.co)}
                        svg
                        style={{
                          width: '1.5em',
                          height: '1.5em',
                        }}
                        title={row.co}
                      />
                    ) : "-"}
                  </td>
                  <td
                    className="text-xs font-medium text-center"
                    style={{ color: "#fbfbd4" }}
                  >
                    {getStateAbbreviation(row.state, row.co) || "-"}
                  </td>
                  <td
                    className="text-xs font-medium text-center"
                    style={{ color: "#fbfbd4" }}
                  >
                    {getCityAbbreviation(row.city)}
                  </td>
                  <td
                    className="text-xs font-medium text-center"
                    style={{ color: "#dba418", fontSize: "17px" }}
                  >
                    {row.player || "-"}
                  </td>
                  <td
                    className="text-xs font-medium text-center"
                    style={
                      {
                        // border: "2px solid green",
                      }
                    }
                  >
                    {ranks[index] || "-"}
                  </td>
                  <td
                    className="text-xs font-medium text-center"
                    style={{
                      border: "2px solid black",
                      color: parseFloat(tp) === 0 ? "red" : "white", // Text color logic
                      backgroundColor:
                        parseFloat(tp) !== 0 ? "green" : "transparent", // No background for icon, gold for value
                      textShadow:
                        parseFloat(tp) !== 0 ? "2px 2px 3px black" : "none", // Text shadow for non-0.00
                    }}
                  >
                    {parseFloat(tp) === 0 ? (
                      <CloseIcon style={{ color: "red", fontSize: "1.3em" }} />
                    ) : (
                      tp || "-"
                    )}
                  </td>
                  <td
                    className="text-xs font-medium text-center"
                    style={{
                      border: "2px solid black",
                      color: parseFloat(row.BR) === 0 ? "red" : "white", // Text color logic
                      backgroundColor: "transparent", // Always transparent for consistent styling
                      position: "relative", // Enable positioning for child elements
                      textShadow:
                        parseFloat(row.BR) !== 0 ? "2px 2px 3px black" : "none", // Text shadow for non-zero value
                      padding: "10px", // Add padding if needed
                      height: "60px", // Set a fixed height for the cell
                    }}
                  >
                    {/* Background image */}
                    {parseFloat(row.BR) !== 0 && (
                      <div
                        style={{
                          backgroundImage: `url(${UBRbg})`,
                          backgroundSize: "40px 30px",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "top center", // Align background image to the top center
                          position: "absolute", // Position the background image absolutely
                          top: 7, // Align it to the top
                          left: "50%", // Center it horizontally
                          transform: "translateX(-50%)", // Center it properly
                          height: "30px", // Set a height for the background image
                          width: "40px", // Set a width for the background image
                        }}
                      />
                    )}

                    <div
                      style={{
                        position: "absolute", // Position text absolutely
                        bottom: "4px", // Align to the bottom with some margin
                        left: "50%", // Center it horizontally
                        transform: "translateX(-50%)", // Center it properly
                        color: "gold",
                      }}
                    >
                      {parseFloat(row.BR) === 0 ? (
                        <CloseIcon
                          style={{ color: "red", fontSize: "1.3em" , marginTop: '-35px' }}
                        />
                      ) : (
                        row.BR || "-"
                      )}
                    </div>
                  </td>
                

                  <td
                    className="text-xs font-medium text-center"
                    style={{
                      border: "2px solid black",
                      color: "white",
                      backgroundColor: 
                        ((parseInt(row.pick_visitor) > parseInt(row.pick_home) && parseInt(gameData.vFinalScore) > parseInt(gameData.hFinalScore)) ||
                        (parseInt(row.pick_home) > parseInt(row.pick_visitor) && parseInt(gameData.hFinalScore) > parseInt(gameData.vFinalScore))) &&
                        parseFloat(ml) !== 0
                          ? "green" 
                          : "transparent",
                      textShadow: parseFloat(ml) !== 0 ? "2px 2px 3px black" : "none"
                    }}
                  >
                    {((parseInt(row.pick_visitor) > parseInt(row.pick_home) && parseInt(gameData.vFinalScore) > parseInt(gameData.hFinalScore)) ||
                      (parseInt(row.pick_home) > parseInt(row.pick_visitor) && parseInt(gameData.hFinalScore) > parseInt(gameData.vFinalScore))) &&
                      parseFloat(ml) !== 0 ? (
                        ml || "-"
                      ) : (
                        <CloseIcon style={{ color: "red", fontSize: "1.3em" }} />
                      )}
                  </td>

                  <td
                    className="text-xs font-medium text-center"
                    style={{
                      border: "2px solid black",
                      color: parseFloat(spread) === 0 ? "red" : "white", // Text color logic
                      backgroundColor:
                        parseFloat(spread) !== 0 ? "green" : "transparent", // No background for icon, gold for value
                      textShadow:
                        parseFloat(spread) !== 0 ? "2px 2px 3px black" : "none", // Text shadow for non-0.00
                    }}
                  >
                    {parseFloat(spread) === 0 ? (
                      <CloseIcon style={{ color: "red", fontSize: "1.3em" }} />
                    ) : (
                      spread || "-"
                    )}
                  </td>
                  <td
                        className="text-xs font-medium text-center"
                        style={{
                          border: "2px solid black",
                          color: parseFloat(ou) === 0 ? "red" : "white", // Text color logic
                          backgroundColor:
                            parseFloat(ou) !== 0 ? "green" : "transparent", // No background for icon, gold for value
                          textShadow:
                            parseFloat(ou) !== 0 ? "2px 2px 3px black" : "none", // Text shadow for non-0.00
                        }}
                      >
                        {parseFloat(ou) === 0 ? (
                          <CloseIcon
                            style={{ color: "red", fontSize: "1.3em" }}
                          />
                        ) : (
                          ou || "-"
                        )}
                      </td>
                  <td
                    className="text-xs font-medium text-center"
                    style={{
                      border: "2px solid black",
                      color: parseFloat(metrics.oneS) === 0 ? "red" : "white",
                      backgroundColor:
                        parseFloat(metrics.oneS) !== 0
                          ? "green"
                          : "transparent",
                      textShadow:
                        parseFloat(metrics.oneS) !== 0
                          ? "2px 2px 3px black"
                          : "none",
                    }}
                  >
                    {parseFloat(metrics.oneS) === 0 ? (
                      <CloseIcon style={{ color: "red", fontSize: "1.3em" }} />
                    ) : (
                      metrics.oneS || "-"
                    )}
                  </td>
                  <td
                    className="text-xs font-medium text-center"
                    style={{
                      border: "2px solid black",
                      color: parseFloat(metrics.oneSO) === 0 ? "red" : "white",
                      backgroundColor:
                        parseFloat(metrics.oneSO) !== 0
                          ? "green"
                          : "transparent",
                      textShadow:
                        parseFloat(metrics.oneSO) !== 0
                          ? "2px 2px 3px black"
                          : "none",
                    }}
                  >
                    {parseFloat(metrics.oneSO) === 0 ? (
                      <CloseIcon style={{ color: "red", fontSize: "1.3em" }} />
                    ) : (
                      metrics.oneSO || "-"
                    )}
                  </td>

                  {/* Only render 1S and 1S0 for NHL */}
                  
                  {selectedLeague === "NFL" && (
                    <>
                      <td
                        className="text-xs font-medium text-center"
                        style={{
                          border: "2px solid black",
                          color: parseFloat(metrics.twoSO) === 0 ? "red" : "white",
                          backgroundColor:
                            parseFloat(metrics.twoSO) !== 0 ? "green" : "transparent",
                          textShadow:
                            parseFloat(metrics.twoSO) !== 0 ? "2px 2px 3px black" : "none",
                        }}
                      >
                        {parseFloat(metrics.twoSO) === 0 ? (
                          <CloseIcon
                            style={{ color: "red", fontSize: "1.3em" }}
                          />
                        ) : (
                          metrics.twoSO || "-"
                        )}
                      </td>
                      <td
                        className="text-xs font-medium text-center"
                        style={{
                          border: "2px solid black",
                          color:
                            parseFloat(metrics.oneSW3) === 0 ? "red" : "white",
                          backgroundColor:
                            parseFloat(metrics.oneSW3) !== 0
                              ? "green"
                              : "transparent",
                          textShadow:
                            parseFloat(metrics.oneSW3) !== 0
                              ? "2px 2px 3px black"
                              : "none",
                        }}
                      >
                        {parseFloat(metrics.oneSW3) === 0 ? (
                          <CloseIcon
                            style={{ color: "red", fontSize: "1.3em" }}
                          />
                        ) : (
                          metrics.oneSW3 || "-"
                        )}
                      </td>
                      <td
                        className="text-xs font-medium text-center"
                        style={{
                          border: "2px solid black",
                          color:
                            parseFloat(metrics.twoSW3) === 0 ? "red" : "white",
                          backgroundColor:
                            parseFloat(metrics.twoSW3) !== 0
                              ? "green"
                              : "transparent",
                          textShadow:
                            parseFloat(metrics.twoSW3) !== 0
                              ? "2px 2px 3px black"
                              : "none",
                        }}
                      >
                        {parseFloat(metrics.twoSW3) === 0 ? (
                          <CloseIcon
                            style={{ color: "red", fontSize: "1.3em" }}
                          />
                        ) : (
                          metrics.twoSW3 || "-"
                        )}
                      </td>
                      <td
                        className="text-xs font-medium text-center"
                        style={{
                          border: "2px solid black",
                          color:
                            parseFloat(metrics.oneSW7) === 0 ? "red" : "white",
                          backgroundColor:
                            parseFloat(metrics.oneSW7) !== 0
                              ? "green"
                              : "transparent",
                          textShadow:
                            parseFloat(metrics.oneSW7) !== 0
                              ? "2px 2px 3px black"
                              : "none",
                        }}
                      >
                        {parseFloat(metrics.oneSW7) === 0 ? (
                          <CloseIcon
                            style={{ color: "red", fontSize: "1.3em" }}
                          />
                        ) : (
                          metrics.oneSW7 || "-"
                        )}
                      </td>
                      <td
                        className="text-xs font-medium text-center"
                        style={{
                          border: "2px solid black",
                          color:
                            parseFloat(metrics.twoSW7) === 0 ? "red" : "white",
                          backgroundColor:
                            parseFloat(metrics.twoSW7) !== 0
                              ? "green"
                              : "transparent",
                          textShadow:
                            parseFloat(metrics.twoSW7) !== 0
                              ? "2px 2px 3px black"
                              : "none",
                        }}
                      >
                        {parseFloat(metrics.twoSW7) === 0 ? (
                          <CloseIcon
                            style={{ color: "red", fontSize: "1.3em" }}
                          />
                        ) : (
                          metrics.twoSW7 || "-"
                        )}
                      </td>
                    </>
                  )}
                  {selectedLeague === "NBA" && (
                    <>
                      <td
                        className="text-xs font-medium text-center"
                        style={{
                          border: "2px solid black",
                          color:
                            parseFloat(metrics.oneSW3) === 0 ? "red" : "white",
                          backgroundColor:
                            parseFloat(metrics.oneSW3) !== 0
                              ? "green"
                              : "transparent",
                          textShadow:
                            parseFloat(metrics.oneSW3) !== 0
                              ? "2px 2px 3px black"
                              : "none",
                        }}
                      >
                        {parseFloat(metrics.oneSW3) === 0 ? (
                          <CloseIcon
                            style={{ color: "red", fontSize: "1.3em" }}
                          />
                        ) : (
                          metrics.oneSW3 || "-"
                        )}
                      </td>
                      <td
                        className="text-xs font-medium text-center"
                        style={{
                          border: "2px solid black",
                          color:
                            parseFloat(metrics.twoSW3) === 0 ? "red" : "white",
                          backgroundColor:
                            parseFloat(metrics.twoSW3) !== 0
                              ? "green"
                              : "transparent",
                          textShadow:
                            parseFloat(metrics.twoSW3) !== 0
                              ? "2px 2px 3px black"
                              : "none",
                        }}
                      >
                        {parseFloat(metrics.twoSW3) === 0 ? (
                          <CloseIcon
                            style={{ color: "red", fontSize: "1.3em" }}
                          />
                        ) : (
                          metrics.twoSW3 || "-"
                        )}
                      </td>
                      <td
                        className="text-xs font-medium text-center"
                        style={{
                          border: "2px solid black",
                          color:
                            parseFloat(metrics.oneSW7) === 0 ? "red" : "white",
                          backgroundColor:
                            parseFloat(metrics.oneSW7) !== 0
                              ? "green"
                              : "transparent",
                          textShadow:
                            parseFloat(metrics.oneSW7) !== 0
                              ? "2px 2px 3px black"
                              : "none",
                        }}
                      >
                        {parseFloat(metrics.oneSW7) === 0 ? (
                          <CloseIcon
                            style={{ color: "red", fontSize: "1.3em" }}
                          />
                        ) : (
                          metrics.oneSW7 || "-"
                        )}
                      </td>
                      <td
                        className="text-xs font-medium text-center"
                        style={{
                          border: "2px solid black",
                          color:
                            parseFloat(metrics.twoSW7) === 0 ? "red" : "white",
                          backgroundColor:
                            parseFloat(metrics.twoSW7) !== 0
                              ? "green"
                              : "transparent",
                          textShadow:
                            parseFloat(metrics.twoSW7) !== 0
                              ? "2px 2px 3px black"
                              : "none",
                        }}
                      >
                        {parseFloat(metrics.twoSW7) === 0 ? (
                          <CloseIcon
                            style={{ color: "red", fontSize: "1.3em" }}
                          />
                        ) : (
                          metrics.twoSW7 || "-"
                        )}
                      </td>
                    </>
                  )}
                  {selectedLeague === "MLB" && (
                    <>
                      
                      <td
                        className="text-xs font-medium text-center"
                        style={{
                          border: "2px solid black",
                          color:
                            parseFloat(metrics.oneSW3) === 0 ? "red" : "white",
                          backgroundColor:
                            parseFloat(metrics.oneSW3) !== 0
                              ? "green"
                              : "transparent",
                          textShadow:
                            parseFloat(metrics.oneSW3) !== 0
                              ? "2px 2px 3px black"
                              : "none",
                        }}
                      >
                        {parseFloat(metrics.oneSW3) === 0 ? (
                          <CloseIcon
                            style={{ color: "red", fontSize: "1.3em" }}
                          />
                        ) : (
                          metrics.oneSW2 || "-"
                        )}
                      </td>
                      <td
                        className="text-xs font-medium text-center"
                        style={{
                          border: "2px solid black",
                          color:
                            parseFloat(metrics.twoSW3) === 0 ? "red" : "white",
                          backgroundColor:
                            parseFloat(metrics.twoSW3) !== 0
                              ? "green"
                              : "transparent",
                          textShadow:
                            parseFloat(metrics.twoSW3) !== 0
                              ? "2px 2px 3px black"
                              : "none",
                        }}
                      >
                        {parseFloat(metrics.twoSW3) === 0 ? (
                          <CloseIcon
                            style={{ color: "red", fontSize: "1.3em" }}
                          />
                        ) : (
                          metrics.twoSW2 || "-"
                        )}
                      </td>
                    </>
                  )}
                  {/* Render Reg, OT, SO columns */}
                  {renderColumns(row)}
                </tr>
              );
            })
          ) : (
            <tr>
              <td
                colSpan={filteredHeaderOptions.length}
                className="text-xs font-medium text-center"
              >
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TableComponent;