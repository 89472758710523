import api from "./api";
import { format } from "date-fns";

export const addGame = async (data) => {
  const response = await api.post(
    "api/admin/games/createGame",
    JSON.stringify(data)
  );
  // console.log("Response:", response);
  return response;
};

export const updateGame = async (data) => {
  const response = await api.patch(
    `api/admin/games/updateGame/${data._id}`,
    JSON.stringify(data)
  );

  return response;
};

export const getGames = async ({ queryKey }) => {
  const [_, date, selectedLeague] = queryKey;

  if (selectedLeague === "NFL") {
    try {
      // For NFL, fetch games for the entire week
      const weekRange = getNFLWeekRange(date);
      const allGames = [];

      // Fetch games for each day in the week range
      for (let currentDate = new Date(weekRange.start); 
           currentDate <= new Date(weekRange.end); 
           currentDate.setDate(currentDate.getDate() + 1)) {
        const formattedDate = format(currentDate, "MM-dd-yyyy");
        const response = await api.get(
          `api/admin/games/getAllGames/${selectedLeague}/${formattedDate}`
        );
        if (response.data && Array.isArray(response.data)) {
          allGames.push(...response.data);
        }
      }

      // Sort games by date and time
      const sortedGames = allGames.sort((a, b) => {
        const dateA = new Date(`${a.gamedate} ${a.time}`);
        const dateB = new Date(`${b.gamedate} ${b.time}`);
        return dateA - dateB;
      });

      return {
        data: sortedGames
      };
    } catch (error) {
      console.error("Error fetching NFL games:", error);
      throw error;
    }
  } else {
    // Other leagues - get today and tomorrow's games without 24hr filter
    const currentDateTime = new Date();
    const next24Hours = new Date(currentDateTime);
    next24Hours.setHours(currentDateTime.getHours() + 24);

    try {
      // Get all games for today and tomorrow
      const currentDateResponse = await api.get(
        `api/admin/games/getAllGames/${selectedLeague}/${format(currentDateTime, "MM-dd-yyyy")}`
      );
      
      const tomorrowResponse = await api.get(
        `api/admin/games/getAllGames/${selectedLeague}/${format(next24Hours, "MM-dd-yyyy")}`
      );

      // Combine all games without 24hr filtering
      const allGames = [
        ...(currentDateResponse.data || []),
        ...(tomorrowResponse.data || [])
      ];

      // Sort by date and time
      const sortedGames = allGames.sort((a, b) => {
        const dateA = new Date(`${a.gamedate} ${a.time}`);
        const dateB = new Date(`${b.gamedate} ${b.time}`);
        return dateA - dateB;
      });

      return {
        ...currentDateResponse,
        data: sortedGames
      };
    } catch (error) {
      console.error("Error fetching games:", error);
      throw error;
    }
  }
};

// Helper function to get NFL week range
const getNFLWeekRange = (date) => {
  const today = date ? new Date(date) : new Date();
  const currentDay = today.getDay(); // 0 = Sunday, 1 = Monday, 2 = Tuesday, etc.
  
  // Calculate days to previous Tuesday
  const daysToTuesday = currentDay >= 2 ? -(currentDay - 2) : -(currentDay + 5);
  
  const startDate = new Date(today);
  startDate.setDate(today.getDate() + daysToTuesday);
  
  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 6); // 6 days after Tuesday = Monday
  
  return {
    start: format(startDate, "MM-dd-yyyy"),
    end: format(endDate, "MM-dd-yyyy")
  };
};

export const fetchGamesForNext10Days = async (selectedLeague) => {
  // Generate an array of dates from today to the next 10 days
  const dates = Array.from({ length: 11 }, (_, i) => {
    const date = new Date();
    date.setDate(date.getDate() + i);
    return format(date, "MM-dd-yyyy");
  });

  // Fetch data for each date
  const fetchPromises = dates.map((date) =>
    api
      .get(`api/admin/games/getAllGames/${selectedLeague}/${date}`)
      .then((response) => response.data)
      .catch((error) => {
        console.error(`Failed to fetch data for date ${date}:`, error);
        return null;
      })
  );

  // Wait for all requests to complete and filter out any null results
  const gamesData = await Promise.all(fetchPromises);
  const validGames = gamesData
    .filter(Boolean)
    .flatMap(response => response)
    .filter(game => game.league === selectedLeague);

  return validGames;
};

export const getGameById = async (id) => {
  const response = await api.get(`api/getGame/${id}`);

  return response;
};

export const enterGameResults = async (data) => {
  const { game_id, ...rest } = data;
  const response = await api.patch(
    `api/admin/games/updateGame/${game_id}`,
    JSON.stringify(rest)
  );

  return response;
};

export const getGamesByDate = async (date) => {
  const response = await api.get(`api/admin/games/getGameBydate/${date}`);

  return response;
};

export const updateGameFields = async (data) => {
  const response = await api.patch(
    `api/admin/games/updateGameFields/${data._id}`,
    JSON.stringify(data)
  );

  return response;
};

export const checkGameExists = async (games) => {
  try {
    const response = await api.post(
      "api/admin/games/checkGameExists",
      JSON.stringify({ games })
    );
    
    console.log("Check games response:", response.data); // Debug log
    
    return {
      exists: response.data.exists,
      existingGames: response.data.existingGames || [],
      message: response.data.message
    };
  } catch (error) {
    console.error("Error checking existing games:", error);
    throw error;
  }
};

export const getSubmittedGames = async () => {
  try {
    const response = await api.get("api/admin/games/getSubmittedGames");
    
    if (!response.data) {
      throw new Error("No data received from server");
    }

    // Check if response has the expected structure
    if (response.data.success === false) {
      throw new Error(response.data.message || "Failed to fetch submitted games");
    }

    return response.data.games; // Return just the games array from the response

  } catch (error) {
    console.error("Error fetching submitted games:", error);
    throw error;
  }
};
