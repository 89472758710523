import api from "./api";
import axios from "axios";
import { API_URL } from "../config/config";

// Add a cache for predictions
let predictionsCache = null;

export const addPrediction = async (data) => {
  const response = await api.post(
    "api/user/gamesplayed/create",
    JSON.stringify(data)
  );
  return response;
};

export const getGamePlayedByUserId = async (userId) => {
  const response = await api.get(`api/user/gamesplayed/gamePlayed/${userId}`);
  return response;
};

export const getGamesPlayedByDate = async (date) => {
  const response = await api.get(
    `api/user/gamesplayed/games-played-by-date/${date}`
  );
  return response;
};

export const getGamesPlayedByDateRange = async (startDate, endDate) => {
  const response = await api.get(
    `api/user/gamesplayed/games-played-by-date-range/${startDate}/${endDate}`
  );
  return response;
};

// Function to get all predictions at once
export const getAllPredictions = async (userId) => {
  try {
    const response = await api.get(`api/user/gamesplayed/gamePlayed/${userId}`);
    const predictions = response?.data?.data?.gamesPlayed || [];
    
    // Create a map for faster lookups
    predictionsCache = predictions.reduce((acc, pred) => {
      acc[pred.gameData] = {
        pick_visitor: pred.pick_visitor,
        pick_home: pred.pick_home,
        gameEnding: pred.gameEnding,
        Pick_ot: pred.Pick_ot,
        Pick_so: pred.Pick_so,
        Pick_Reg: pred.Pick_Reg,
        isLocked: pred.isLocked
      };
      return acc;
    }, {});
    
    return predictionsCache;
  } catch (error) {
    console.error("Error fetching all predictions:", error);
    return null;
  }
};

// Modified function to fetch fresh data
export const getPredictionByGameAndUser = async (gameId, userId) => {
  try {
    const response = await api.get(`api/user/gamesplayed/prediction/${gameId}/${userId}`);
    console.log('Raw API Response for game:', gameId, response);
    
    // Handle different response structures
    if (response?.data?.data?.gamesPlayed) {
      // Response from getAllPredictions format
      const prediction = response.data.data.gamesPlayed.find(p => p.gameData === gameId);
      if (prediction) {
        return {
          data: {
            pick_visitor: prediction.pick_visitor,
            pick_home: prediction.pick_home,
            gameEnding: prediction.gameEnding,
            Pick_ot: prediction.Pick_ot,
            Pick_so: prediction.Pick_so,
            Pick_Reg: prediction.Pick_Reg,
            isLocked: prediction.isLocked
          }
        };
      }
    }
    
    // Handle single prediction response
    if (response?.data?.data) {
      return {
        data: {
          pick_visitor: response.data.data.pick_visitor,
          pick_home: response.data.data.pick_home,
          gameEnding: response.data.data.gameEnding,
          Pick_ot: response.data.data.Pick_ot,
          Pick_so: response.data.data.Pick_so,
          Pick_Reg: response.data.data.Pick_Reg,
          isLocked: response.data.data.isLocked
        }
      };
    }
    
    // If data is directly on response
    if (response?.data) {
      return {
        data: {
          pick_visitor: response.data.pick_visitor,
          pick_home: response.data.pick_home,
          gameEnding: response.data.gameEnding,
          Pick_ot: response.data.Pick_ot,
          Pick_so: response.data.Pick_so,
          Pick_Reg: response.data.Pick_Reg,
          isLocked: response.data.isLocked
        }
      };
    }
    
    return null;
  } catch (error) {
    console.error("Error fetching prediction for game:", gameId, error);
    return null;
  }
};

export const getPredictionByGameId = async (gameId) => {
  try {
    const userId = localStorage.getItem("_id");
    const response = await api.get(`api/user/gamesplayed/prediction/${gameId}/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching prediction:", error);
    return null;
  }
};
