import React, { useEffect, useState } from "react";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import Line from "../components/Line.js";
import GameCard from "../components/GameCard/GameCard.js";
import { getGames } from "../Apis/games.js";
import { useQuery } from "react-query";
import { format } from "date-fns";
import { useLeagueContext } from "../components/LeagueContext.js";
import banner from "../assets/FreeYearBanner.gif";

const Games = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isAdmin = true;
  const { selectedLeague } = useLeagueContext();
  const [gameData, setGameData] = useState([]);
  const [openRow, setOpenRow] = useState(null);

  const date = new Date();
  const formattedDateForAPI = format(date, "MM-dd-yyyy");

  const { refetch: refetchGames } = useQuery(
    ["teams", formattedDateForAPI, selectedLeague], 
    async () => {
      const response = await getGames({ 
        queryKey: ["teams", formattedDateForAPI, selectedLeague] 
      });

      // Filter games for selected league
      const leagueGames = response.data.filter(game => game.league === selectedLeague);

      const sortedGames = leagueGames.sort((a, b) => {
        const dateA = new Date(a.gamedate + ' ' + a.time);
        const dateB = new Date(b.gamedate + ' ' + b.time);
        return dateA - dateB;
      });

      return {
        ...response,
        data: sortedGames
      };
    }, 
    {
      onSuccess: (fetchedData) => {
        if (fetchedData && fetchedData.data) {
          updateGamesIfChanged(fetchedData.data);
        }
      },
      enabled: false
    }
  );

  useEffect(() => {
    setGameData([]); // Clear current games when league changes
    refetchGames();
    const intervalId = setInterval(() => {
      refetchGames();
    }, 30000);
    return () => clearInterval(intervalId);
  }, [selectedLeague]);

  const updateGamesIfChanged = (newGames) => {
    const currentGameIds = new Set(gameData.map(game => game._id));
    const hasNewGames = newGames.some(game => !currentGameIds.has(game._id));
    if (hasNewGames) {
      setGameData(newGames);
    }
  };

  const handleToggle = (index) => {
    setOpenRow(openRow === index ? null : index);
  };

  const groupGamesByDay = (games) => {
    if (!games) return [];
    
    const grouped = games.reduce((acc, game) => {
      const gameDateTime = new Date(game.gamedate + ' ' + game.time);
      const dayKey = format(gameDateTime, 'EEEE, MMMM d');
      
      const timeStr = format(gameDateTime, 'h:mm a');
      game.displayTime = timeStr;
      
      if (!acc[dayKey]) {
        acc[dayKey] = [];
      }
      acc[dayKey].push(game);
      return acc;
    }, {});

    return Object.entries(grouped).sort(([dateA], [dateB]) => {
      return new Date(dateA) - new Date(dateB);
    });
  };

  return (
    <Grid container spacing={4} direction="column">
      <img src={banner} alt="img1" className="w-full" />
      <Grid item>
        <Line />
      </Grid>
      
      {gameData && gameData.length > 0 ? (
        groupGamesByDay(gameData).map(([day, gamesForDay]) => (
          <React.Fragment key={day}>
            <Grid item>
              <Typography 
                variant="h5" 
                sx={{
                  color: 'white',
                  padding: '10px',
                  backgroundColor: '#1a1a1a',
                  marginBottom: '-30px',
                  marginTop: '0px',
                  borderRadius: '5px',
                  width: isMobile ? "98%" : "107%",
                  marginLeft: isMobile ? "-10px" : "-120px",
                }}
              >
                {day}
              </Typography>
            </Grid>
            <Grid
              className="game-grid"
              container
              item
              spacing={2}
              justifyContent="flex-start"
              sx={{
                width: isMobile ? "98%" : "115%",
                marginLeft: isMobile ? "-10px" : "-130px",
              }}
            >
              {gamesForDay.map((game, index) => (
                <Grid key={game._id} item xs={12} md={4}>
                  <GameCard
                    gameData={game}
                    isOpen={openRow === index}
                    onToggle={() => handleToggle(index)}
                    isAdmin={isAdmin}
                  />
                </Grid>
              ))}
            </Grid>
          </React.Fragment>
        ))
      ) : (
        <Grid 
          item 
          sx={{
            width: isMobile ? "98%" : "115%",
            marginLeft: isMobile ? "-10px" : "-130px",
          }}
        >
          <Typography
            variant="h5"
            color="textSecondary"
            style={{ 
              color: "white", 
              margin: "auto",
              textAlign: "center",
              width: "100%",
              padding: "20px"
            }}
          >
            No games available for {selectedLeague}.
          </Typography>
        </Grid>
      )}
      <Grid item>
        <Line />
      </Grid>
    </Grid>
  );
};

export default Games;
